import qs from 'query-string'

/**
 * Add tracking queries into provided URL
 * @param {string} url
 * @param {string} source
 * @param {string} campaign
 * @param {string} medium
 * @returns {string}
 */
export function getTrackingURL(url, medium = '', source = '', campaign = '', content = '') {
  // Parse provided URL into object
  const urlObj = qs.parseUrl(url)
  if (urlObj && urlObj.url && urlObj.query) {
    // Add UTM tracking queries
    urlObj.query.utm_medium = medium
    urlObj.query.utm_source = source
    urlObj.query.utm_campaign = campaign
    urlObj.query.utm_content = content

    // Stringify URL object
    // and remove empty queries
    return qs.stringifyUrl(urlObj, {
      skipEmptyString: true,
    })
  }
  return ''
}
